<template>
	<div class="auth-wrapper auth-v2">
		<b-row class="auth-inner m-0">

			<!-- Brand logo-->
			<b-link class="brand-logo">
			<!-- <vuexy-logo /> -->
				<img v-if="isMobile" :src="logo" height="50">
				<h2 v-if="isMobile" class="brand-text text-center ml-1 mt-1" style="color: #e51b23!important;">FERREYSA</h2>
			</b-link>
			<!-- /Brand logo-->

			<!-- Left Text-->
			<b-col lg="8" class="d-none d-lg-flex align-items-center p-2">
				<div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
					<b-img height="400px" :src="logo_1" alt="Ferreysa" />
				</div>
			</b-col>
			<!-- /Left Text-->

			<!-- Login-->
			<b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
				<b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">

					<b-alert variant="danger" :show="isInactivity">
						<div class="alert-body">
							<span><strong>Cierre de Sesión!</strong> por inactividad</span>
						</div>
					</b-alert>
					<b-card-title v-if="!isMobile" title-tag="h2" class="font-weight-bold mb-1">
						Bienvenido a Ferreysa! 👋
					</b-card-title>
					<b-card-text class="mb-2">
						Inicie sesión en su cuenta y comience la aventura.
					</b-card-text>

					<!-- form -->
					<validation-observer ref="loginValidation">
						<b-form class="auth-login-form mt-2" @submit.prevent>
							<!-- email -->
							<b-form-group label="Usuario" label-for="login-email">
								<validation-provider #default="{ errors }" name="Usuario" rules="required">
									<b-form-input id="login-email" v-model="form.userName"
										:state="errors.length > 0 ? false : null" name="login-email"
										placeholder="Usuario" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<!-- forgot password -->
							<b-form-group>
								<div class="d-flex justify-content-between">
									<label for="login-password">Contraseña</label>
									<!-- <b-link :to="{ name: 'auth-forgot-password-v2' }">
										<small>Forgot Password?</small>
									</b-link> -->
								</div>
								<validation-provider #default="{ errors }" name="Contraseña" rules="required">
									<b-input-group class="input-group-merge"
										:class="errors.length > 0 ? 'is-invalid' : null">
										<b-form-input id="login-password" v-model="form.password"
											:state="errors.length > 0 ? false : null" class="form-control-merge"
											:type="passwordFieldType" name="login-password"
											placeholder="············" />
										<b-input-group-append is-text>
											<feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
												@click="togglePasswordVisibility" />
										</b-input-group-append>
									</b-input-group>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<!-- Web Connections -->
							<validation-provider #default="{ errors }" name="Conexion" rules="required">
								<b-form-group label="Conexión" description="Selecciona una conexión"
									label-for="idEmpresa" :state="errors.length > 0 ? false : null">
									<v-select id="idEmpresa" v-model="form.idEmpresa" :options="connections"
										:reduce="option => option.idEmpresa" label="nombre"
										placeholder="Selecciona una opción" :clearable="false" :searchable="false"
										@input="selectConexion">
									</v-select>
								</b-form-group>
							</validation-provider>

							<!-- Branches -->
							<validation-provider #default="{ errors }" name="Sucursal" rules="required">
								<b-form-group label="Sucursal" description="Selecciona una sucursal"
									label-for="sucursal_Id" :state="errors.length > 0 ? false : null">
									<v-select id="sucursal_Id" v-model="form.sucursalId" :options="branches"
										:reduce="option => option.sucursal_Id" label="nombre"
										placeholder="Selecciona una opción" :clearable="false" :searchable="false">
									</v-select>
								</b-form-group>
							</validation-provider>

							<!-- checkbox -->
							<b-form-group>
								<!-- <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
								Remember Me
								</b-form-checkbox> -->
							</b-form-group>

							<!-- submit buttons -->
							<b-button :disabled="loading" type="submit" variant="primary" block @click="validationForm">
								Iniciar Sesión
								<b-spinner v-if="loading" small />
							</b-button>
						</b-form>
					</validation-observer>
				</b-col>
			</b-col>
			<!-- /Login-->
		</b-row>
	</div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
	BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
	BOverlay, BSpinner, BAlert,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import vSelect from "vue-select";
import store from '@/store'
import router from '@/router'
import Swal from "sweetalert2";
import "animate.css";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { v4 as uuidv4 } from 'uuid';
import useAppConfig from '@core/app-config/useAppConfig'

import WareHousesProvider from '@/providers/WareHouses'
const WareHousesResource = new WareHousesProvider()

import BranchesProvider from "@/providers/Branches"
const BranchesResouce = new BranchesProvider()

import ConnectionsProvider from "@/providers/Connections"
const ConnectionsResource = new ConnectionsProvider()

import AuthProvider from '@/providers/Auth'
const AuthResource = new AuthProvider()


export default {
	components: {
		BRow,
		BCol,
		BLink,
		BFormGroup,
		BFormInput,
		BInputGroupAppend,
		BInputGroup,
		BFormCheckbox,
		BCardText,
		BCardTitle,
		BImg,
		BForm,
		BButton,
		BOverlay,
		BSpinner,
		VuexyLogo,
		BAlert,
		ValidationProvider,
		ValidationObserver,

		//
		vSelect,
	},
	mixins: [togglePasswordVisibility],
	data() {
		return {
			loading: false,
			status: '',
			form: {
				userName: null,
				password: null,
				idEmpresa: null,
				id_Padre: null,
				almacen_Id: null,
				sucursalId: null,
				deviceId: null,
			},
			sideImg: require('@/assets/images/pages/login-v2.svg'),
			logo: require('@/assets/images/logo/ferreysa.png'),
			logo_1: require('@/assets/images/logo/ferreysa_1.jpg'),
			logo_2: require('@/assets/images/logo/ferreysa_2.jpg'),
			logo_3: require('@/assets/images/logo/ferreysa_3.jpg'),
			// validation rulesimport store from '@/store/index'
			required,
			email,
			branches: [],
			wareHouses: [],
			connections: [],
			isMobile: false,
			settingPurchase: {
				columns: [
					{
						key: 'show_details',
						label: '',
						description: '',
					},
					{
						key: "clave_Articulo",
						label: "Clave",
						visible: true,
						sortable: true,
						description: 'Clave del Articulo',
						thStyle: 'font-size: 0.7rem; outline: initial!important;'
					},
					{
						key: "nombre",
						label: "Articulo",
						visible: true,
						sortable: true,
						description: 'Nombre del Articulo',
						//tdClass: 'text-nowrap',
						thStyle: 'font-size: 0.7rem; outline: initial!important;'
					},
					{
						key: "unidad_Venta",
						label: "Unidad V.",
						visible: true,
						sortable: true,
						description: 'Unidad de venta',
						//tdClass: 'text-nowrap',
						thStyle: 'font-size: 0.7rem; outline: initial!important;'
					},
					{
						key: "ultimo_Costo",
						label: "Costo",
						visible: true,
						sortable: true,
						description: 'Ultimo Costo',
						thStyle: 'font-size: 0.7rem; outline: initial!important;'
					},
					{

						//VENTA MOSTRADOR PASA A ROTACION PUNTO VENTA
						key: "rotacion_Pv",
						label: "Rotación PV",
						visible: true,
						sortable: true,
						description: 'Rotación Punto Venta',
						thStyle: 'font-size: 0.7rem; outline: initial!important;'
					},
					{
						key: "rotacion_Ve",
						label: "Rotación Ventas",
						visible: true,
						sortable: true,
						description: 'Rotación Ventas',
						thStyle: 'font-size: 0.7rem; outline: initial!important;'
					},
					{
						key: "devoluciones_Totales",
						label: "Devoluciones",
						visible: true,
						sortable: true,
						description: 'Devoluciones Totales',
						thStyle: 'font-size: 0.7rem; outline: initial!important;'
					},
					{
						key: "salidas_Totales",
						label: "Salidas Por Meses",
						visible: true,
						sortable: true,
						description: 'Salidas Totales',
						thStyle: 'font-size: 0.7rem; outline: initial!important;'
					},
					{
						key: "rotacion_Pb_Ve_Proyectada",
						label: "Rotación Proyectada",
						visible: true,
						sortable: true,
						description: 'Rotación Proyectada',
						thStyle: 'font-size: 0.7rem; outline: initial!important;'
					},
					// {
					//     key: "existencia_Almacen",
					//     label: "Existencia Almacen",
					//     visible: true,
					//     sortable: true,
					//     description: 'Existencia Almacén',
					//     thStyle: 'font-size: 0.7rem; outline: initial!important;'
					// },
					{
						key: "existencia_Total",
						label: "Existencia Total",
						visible: false,
						sortable: true,
						description: 'Existencia Total',
						thStyle: 'font-size: 0.7rem; outline: initial!important;'
					},
					{
						key: "existencia_Almacen",
						label: "Existencia Almacenes",
						visible: true,
						sortable: true,
						description: 'Existencia de Almacenes',
						thStyle: 'font-size: 0.7rem; outline: initial!important;'
					},
					{
						key: "existencia_Sucursales",
						label: "Existencia Sucursales",
						visible: true,
						sortable: true,
						description: 'Existencia de Sucursales',
						thStyle: 'font-size: 0.7rem; outline: initial!important;'
					},
					{
						key: "total_meses",
						label: "Total Meses",  // = existencia almacen / (rotacion pv + totacion v)
						visible: true,
						sortable: true,
						description: 'Total Meses',
						thStyle: 'font-size: 0.7rem; outline: initial!important;'
					},
					{
						key: "fecha_Actualizacion",
						label: "Ult. Actualización",
						visible: true,
						sortable: true,
						description: 'Última Actualización',
						thStyle: 'font-size: 0.7rem; outline: initial!important;'
					},
					{
						key: "articulo_Id",
						label: "Articulo ID",
						visible: true,
						sortable: true,
						description: 'Articulo ID',
						thStyle: 'font-size: 0.7rem; outline: initial!important;'
					},
				],
			}
		}
	},
	computed: {		
		isInactivity() {
			return store.getters['auth/getIsInactivity']
		},
		passwordToggleIcon() {
			return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
		},
		imgUrl() {
			if (store.state.appConfig.layout.skin === 'dark') {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
				return this.sideImg
			}
			return this.sideImg
		},
		isCashier() {
			return store.getters['auth/getUser'] ? (store.getters['auth/getUser'].customRol.id === store.getters['auth/getCashierRol'] ||
				store.getters['auth/getUser'].customRol.id === store.getters['auth/getSuperCashierRol']) : false
		},
		isLogistic() {
			return tore.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id === store.getters['auth/getIsLogisticRol'] : false
		},
		isOutput() {
			return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id === store.getters['auth/getPatioRol'] : false
		},
		isAutorizator() {
			return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id === store.getters['auth/getAutorizatorRol'] : false
		},
		isAdministrator() {
			return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == store.getters['auth/getAdministratorRol'] : false
		},
		isSuperCash() {
			return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == store.getters['auth/getSuperCashierRol'] : false
		},
		isAuxiliar() {
			return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == store.getters['auth/getAuxiliarRol'] : false
		},	
		isSeller() {
			return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == store.getters['auth/getIsRoleSeller'] : false
		},
		isAuthenticatedOnCloud () {
			const tokenData = store.getters['auth/getTokenCloud'];
			if (!tokenData || !tokenData.access_token || !tokenData.expires_at) return false;

			// Usa Moment.js para convertir las fechas y compararlas
			const expiresAt = moment.utc(tokenData.expires_at); // Fecha de expiración en UTC
			const nowUtc = moment.utc();  // El momento actual en UTC por defecto

			// Compara las fechas
			return !expiresAt.isBefore(nowUtc); // Devuelve true si NO ha expirado
		},
	},
	beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
	created() {
		if (process.env.NODE_ENV === 'development') {
			this.form.userName = 'admin'
			this.form.password = 'Adm1n2024_'
		} else {
			this.form.userName = null
			this.form.password = null
		}
	},
	async mounted() {

		window.addEventListener('resize', this.handleResize);
        this.handleResize()

		store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
		store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: 'static' })
		await this.getConnections()
		// await this.getDeviceId();
	},
	methods: {
		handleResize() {
            this.isMobile = window.innerWidth < 998;
        },
		async getDeviceId() {
			// Verificar si el deviceId ya está almacenado en el localStorage
			let deviceId = store.getters['auth/getDeviceId']
			
			// if (!deviceId) {
				// Si no existe, generar uno basado en características del navegador
				const fp = await FingerprintJS.load();
				const result = await fp.get();

				// console.log('FingerprintJS result:', result);

				deviceId = result.visitorId;				
				store.dispatch('auth/UPDATE_DEVICE_ID', deviceId);
			// }

			return deviceId;
		},
		getWareHouses() {
			try {

			} catch (e) {
				this.loading = false
			}
		},
		getLocalHost() {
			const hostname = window.location.hostname;
			if (hostname === 'localost' || hostname === '127.0.0.1') {
				return false
			}
			return hostname === '192.168.1.1';
		},
		async selectConexion(evt) {
			if (evt) {
				const idPadre = this.connections.find(item => item.idEmpresa == evt)
				// if (evt === 1 && !this.getLocalHost()) {
				//   this.$swal.fire({
				//     title: 'Conexión Local',
				//     text: 'Esta conexión solo se puede establecer de forma local. Será redirigido a una nueva ruta. ¿Desea proceder?',
				//     icon: 'question',
				//     showCancelButton: true,
				//     confirmButtonText: 'Continuar',
				//     cancelButtonText: 'Cancelar',
				//     customClass: {
				//         confirmButton: "btn btn-primary",
				//         cancelButton: "btn btn-outline-danger ml-1",
				//     },
				//     showClass: {
				//         popup: "animate__animated animate__flipInX",
				//     },
				//     buttonsStyling: false,
				//   }).then((result) => {
				//     if(result.isConfirmed) {
				//       window.location.href = "http://192.168.1.1:8081"
				//     } else {
				//       this.form.idEmpresa = null
				//       this.form.id_Padre = null,
				//       this.form.almacen_Id = null
				//       this.form.sucursalId = null
				//     }
				//   })
				// } else {
				if (this.form.idEmpresa) {
					this.form.id_Padre = idPadre.id_Padre
					await this.getBranches()
				}
				// }
			}
		},
		async getBranches() {
			try {
				this.branches = []
				this.form.sucursalId = null
				this.loading = true
				const { data } = await BranchesResouce.index(this.form.idEmpresa)
				if (data.isSuccesful) {
					this.branches = data.data
				} else {
					this.danger(data.message)
				}

			} catch (e) {
				this.handleResponseErrors(e)
			} finally {
				this.loading = false
			}
		},
		async getConnections() {
			try {
				this.loading = true
				const { data } = await ConnectionsResource.getByStatus(1) //1 = Activa
				if (data.isSuccesful) {
					this.connections = data.data
					if (this.connections) {
						store.dispatch('auth/UPDATE_CONNECTIONS', this.connections)
					}
				} else {
					this.danger(data.message)
				}
			} catch (e) {
				this.handleResponseErrors(e)
			} finally {
				this.loading = false
			}
		},
		async loginInCloud() {
			const payload = {
				grant_type: process.env.VUE_APP_API_PRICES_GRANT_TYPE,
				client_id: process.env.VUE_APP_API_PRICES_CLIENT_ID,
				client_secret: process.env.VUE_APP_API_PRICES_CLIENT_SECRET,
				scope: process.env.VUE_APP_API_PRICES_SCOPE,
			}
			
			try {			
				const { data } = await axios.post(`${process.env.VUE_APP_API_PRICES}/oauth/token`, payload, {
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
				});				

				if (data) {
					store.commit('auth/SET_TOKEN_CLOUD', data)
					localStorage.setItem('token_cloud', data.access_token)
				}
			}catch(e) {
				console.log(e)
			}
		},
		validationForm() {
			this.$refs.loginValidation.validate().then( async (success) => {
				if (success) {
					this.loading = true

					if (!this.isAuthenticatedOnCloud) {
						console.log('No está autenticado en la nube');
						await this.loginInCloud()
					}

					store.dispatch("auth/UPDATE_IS_INACTIVITY", false);
					store.dispatch('routing/UPDATE_IS_RELOAD_ABIERTA', false)
					store.dispatch('routing/UPDATE_IS_RELOAD_EN_RUTA', false)
					store.dispatch('routing/UPDATE_IS_RELOAD_CERRADA', false)
					store.dispatch('routing/UPDATE_IS_RELOAD_CLIENTE_PASA', false)

					// if (store.getters['auth/getDeviceId']) {
					// 	this.form.deviceId = store.getters['auth/getDeviceId']
					// } else {
						this.form.deviceId = uuidv4()
						store.dispatch('auth/UPDATE_DEVICE_ID', this.form.deviceId)
					// }
					
					store.dispatch("auth/GET_AUTH_TOKEN", this.form).then((response) => {
						this.loading = false
						this.success(`Bienvenido ${this.form.userName}`);
						store.dispatch('auth/UPDATE_BRANCH', this.branches.find(item => item.sucursal_Id === this.form.sucursalId))
						store.dispatch('auth/UPDATE_CONEXION', this.connections.find(item => item.idEmpresa === this.form.idEmpresa))
						store.dispatch('auth/UPDATE_SETTING_PURCHASE', this.settingPurchase)
						const wareHouses = store.getters['auth/getWareHouses']
						let nuevoAlmacen = 0;
						if (store.getters['auth/getUser'].almacenes.length > 0) {
							const wareHouse = wareHouses.find(wh => wh.almacen_Id == store.getters['auth/getUser'].almacenes[0].almacen_Id)
							nuevoAlmacen = wareHouse ? wareHouse.almacen_Id : 0
						}

						//Validamos si existen las conexiones en el store
						if (store.getters['auth/getConnections'].length === 0) {
							store.dispatch('auth/UPDATE_CONNECTIONS', this.connections)
						}

						if (this.isSuperCash) {
							nuevoAlmacen = 0
						}

						store.dispatch('auth/UPDATE_WAREHOUSE_ID', nuevoAlmacen)

						//Verificar si es vendedor:
						if (store.getters['auth/getUser'].customRol.id === store.getters['auth/getIsRoleSeller']) {
							store.dispatch('auth/UPDATE_SELLER_ID', store.getters['auth/getUser'].vendedor_Id)
						}

						localStorage.setItem('searchProductText', '')
						store.commit('app-ecommerce/SET_DETAIL_PRODUCT', null)

						if (this.isCashier /*|| this.isLogistic*/) {
							router.push({ name: 'apps-routings' })
						} else if (this.isOutput || this.isAutorizator || this.isSeller) {
							router.push({ name: 'apps-routings-outputs' })
						} else if (this.isAuxiliar) {
							store.commit("appConfig/UPDATE_CONTENT_WIDTH", "full");
							store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
							router.push({ name: 'apps-e-commerce-shop' })
						} else {
							router.push({ name: 'home' })
						}
					}).catch(error => {
						this.loading = false
						const { data } = error
						if (data) {
							if (!data.IsSuccesful) {
								this.danger(data.message)
							}
						} else {
							this.handleResponseErrors(error);
						}
					})
				}
			})
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import "~@core/scss/vue/libs/vue-select.scss";
</style>